<template>
  <div class="file-viewer">
    <div class="action-buttons">
      <button @click.stop="downloadFile" class="download-button">
        <img src="@/assets/icons/download-file.svg" alt="" />
      </button>
      <button @click.stop="closeViewer" class="close-button">
        <img src="@/assets/icons/close-file.svg" alt="" />
      </button>
    </div>
    <div class="overlay"></div>
    <div class="file-container">
      <div class="file-content">
        <div class="loader" v-if="loading"></div>
        <div v-else>
        <img
        v-if="isImage"
          :src="url"
          alt=""
          class="file-image"
          :style="{ width: naturalWidth, height: naturalHeight }"
        />
        <PdfViewer :evidence="url" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang=" ts">
  import { defineComponent, ref,h } from "vue";
  import { LoadingOutlined } from "@ant-design/icons-vue";
  import PdfViewer from "@/components/FileViewer/PdfViewer.vue"

  export default defineComponent({
    components: {
      PdfViewer
  },
    props: [ "url", "extension"],
    data() {
      return {
        loading: true,
        naturalWidth: "",
        naturalHeight: "",
      };
    },
    computed: {
      isImage() {
  const imageExtensions = ["jpg", "jpeg", "png", "image/jpeg", "image/png",];
  const evidenceExtension = typeof this.extension === 'string' ? this.extension.toLowerCase() : '';
  return imageExtensions.includes(evidenceExtension);
}

    },
    methods: {
      closeViewer() {
      this.$emit('closeViewer');
    },

      loadImage() {
        this.loading = true;
        const img = new Image();
        img.src = this.url;

        img.onload = () => {
          this.naturalWidth =
            img.naturalWidth < 1821 ? img.naturalWidth + "px" : "90%";
          this.naturalHeight =
            img.naturalHeight < 981 ? img.naturalHeight + "px" : "90%";
            this.loading = false;
        };
        img.onerror = () => {
          this.loading = false;
          self.closeViewer();
        };
      },
      downloadFile() {
        window.open(this.url, "_blank");
      },
    },
    watch: {
      extension(newValue, oldValue) {
        this.loadImage();
      },
    },
    mounted() {
      if (this.isImage) {
        this.loadImage();
      } else {
        this.loading = false;
      }
    }
  });
</script>

<style scoped>
.file-viewer {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #282828b2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(40 40 40 / 97%) 0%, rgb(40 40 40 / 0%) 15%);
  pointer-events: none;
}

.action-buttons {
  position: absolute;
  z-index: 10000;
  top: 12px;
  right: 40px;
}
.download-button {
  border: none;
  background: none;
  color: #fff;
}
.close-button {
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
}

.file-container {
  width: 100%;
  height: 100%;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.file-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.file-content img {
  z-index: 10000000;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.file-content .image-container {
  width: 100%;
  height: 100%;
}
.loader {
  width: 40px;
  height: 40px;
  margin: auto;
  animation: spin 1s linear infinite;
  border: 4px solid transparent;
  border-top: 4px solid #1890ff;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
