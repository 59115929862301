<template>
  <div class="pdf-container">
    <div class="file-content" v-if="isLoading">
        <div class="loader"></div>
    </div>
    <div>
      <div v-for="i in pageCount" :key="i" class="pdf-item">
        <pdf
          :src="evidence"
          :id="i"
          :page="i"
          :style="{ width: pageWidth }"
          class="pdf-viewer"
          @page-loaded="onPageLoaded"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  props: {
    evidence: {
      type: String,
      required: false,
    },
    spinning: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '24px'
    }
  },
  computed: {
    indicatorStyle() {
      return {
        width: this.size,
        height: this.size,
      };
    }
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 2,
      numPages: undefined,
      pageHeight: 0,
      pageWidth: 0,
      isLoading: true,
      loadingTask: null,
      renderedPages: 0,
    }
  },
  mounted() {
    this.loadingTask = pdf.createLoadingTask(this.evidence)
    this.loadingTask.promise.then((pdf) => {
      this.pageCount = pdf._pdfInfo.numPages
      pdf.getPage(1).then((firstPage) => {
        let naturalWidth = firstPage.getViewport({ scale: 1 }).width
        this.pageWidth = naturalWidth < 1921 ? naturalWidth + 'px' : '90%'
      })
    })
  },
  methods: {
    onPageLoaded() {
      this.renderedPages++;
      if (this.renderedPages === this.pageCount) {
        this.isLoading = false;
      }
    },
  },
}
</script>
<style scoped>
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 50px;
  padding-bottom: 16px;
  overflow-y: auto;
}
.pdf-container::-webkit-scrollbar {
  width: 0;
}

.file-container {
  width: 100%;
  height: 100%;
}
.pdf-item {
  margin-bottom: 16px;
}
.pdf-viewer {
  padding-bottom: 20px;
}
.file-content {
  position: absolute;
  margin-top: 50vh;
}
.loader {
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  border: 4px solid transparent;
  border-top: 4px solid #1890ff;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
